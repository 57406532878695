/* You can add global styles to this file, and also import other style files */
@import "~animate.css/animate.min.css";

html,
body {
  height: 100%;
}

@bigDesktop: ~"(max-width: 1700px)";
@desktop: ~"(max-width: 1499px)";
@tablet: ~"(max-width: 1023px)";
@mobile: ~"(max-width: 767px)";
@smallMobile: ~"(max-width: 420px)";

body {
  margin: 0;
  font-family: "Roboto Condensed";
  background: white;
  overscroll-behavior-y: contain;
}

h1 {
  font-weight: 300;
  font-size: 40px;
  margin: 10px;
  text-shadow: -1px 2px 2px rgba(0, 0, 0, 0.4), 0 5px 10px rgba(0, 0, 0, 0.1), 0 18px 23px rgba(0, 0, 0, 0.1);
}

h2 {
  font-weight: 300;
  font-size: 34px;
  margin: 8px;
  text-shadow: -1px 2px 2px rgba(0, 0, 0, 0.4), 0 5px 10px rgba(0, 0, 0, 0.1), 0 18px 23px rgba(0, 0, 0, 0.1);
}

h3 {
  font-weight: 300;
  font-size: 30px;
  margin: 5px;
  text-shadow: -1px 2px 2px rgba(0, 0, 0, 0.4), 0 5px 10px rgba(0, 0, 0, 0.1), 0 18px 23px rgba(0, 0, 0, 0.1);
}

h4 {
  margin: 3px;
}

p {
  font-weight: 300;
  font-size: 25px;
  margin: 4px;
  color: #1f1d1d;
}

a {
  font-size: 20px;
  margin: 4px;
}


::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: #2d9db2;
  border-radius: 25px;
}

.display-none {
  display: none;
}

.center {
  text-align: center;
  margin: 0 auto;
}

.container {
  display: flex;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.content-center {
  justify-content: center;
  text-align: center;
}

.content-right {
  justify-content: flex-end;
}

.content-left {
  justify-content: flex-start;
  text-align: left;
}

.list {
  flex-direction: column;
}

.grid {
  flex-wrap: wrap;
}

.m-top-45 {
  margin-top: 45px;
}

.m-5 {
  margin: 5px !important;
}

.content-space-between {
  justify-content: space-between;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-15 {
  width: 15%;
}

@media (max-width: 1000px) {
  .w-sm-50 {
    width: 50%;
  }

  .w-sm-100 {
    width: 100%;
  }
}

@media (max-width: 650px) {
  .w-xs-100 {
    width: 100%;
  }
}



@media @tablet {
  .page {
    height: calc(~"100vh - 9rem");
  }
  .space-top {
    padding-top: 9rem !important;
  }
}


@media @mobile {
  h1 {
    font-size: 32px;
  }
  h3 {
    font-size: 27px;
  }
  w-xs-100 {
    width: 100%;
  }
  p {
    font-size: 25px;
  }
  .help {
    font-size: 17px;
  }

  .w-sm-100 {
    width: 100%;
  }

  .w-xs-20 {
    width: 30%;
  }
}

@media @smallMobile {
  .w-xs-80 {
    width: 80%;
  }

  .w-xs-20 {
    width: 20%;
  }

  h1 {
    font-size: 30px;
  }

  p {
    font-size: 20px;
  }
}

.actions {
  justify-content: space-around;
}

.row {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  place-content: stretch center;
  align-items: stretch;
}

.justify-content-center {
  justify-content: center;
}

.lds-ellipsis {
  z-index: 100;
  position: fixed;
  top: 40%;
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #304269;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}
